// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import "../../../styles/mapStyles.css"
// import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import React  from 'react';

function LocationBlock(){
    
    // const loc = {lat: 49.86757225558472, lng: 24.002640240524695}
    // const {isLoaded} = useLoadScript({
    //     googleMapsApiKey:"AIzaSyDKBMAKQAbcXC0EyJjPsrA5OCfa9vf-YOI"
    // });
    // if(!isLoaded) return <div>Loading...</div>
    return (
        <Box sx={{
            width:"100%"
        }}>
            <Box name="title" sx={{
                width:"100%",
                height:"10em",
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
            }}>
                <Typography sx={{
                    fontFamily: 'OksFree',
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "45px",
                    lineHeight: "27px",
                    color:"#00000",
                }}>Amigos на карті</Typography>
            </Box>
       

            <Box name="map" sx={{
                width:"90%",
                margin:"0 auto"
            }}>
                <iframe title="Google map" className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2572.162258404238!2d24.01515057691027!3d49.85819662982966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473add58603f35bb%3A0x703aa46b78f085e!2sAmigos%20-%20Grooming%20Salon!5e0!3m2!1suk!2sua!4v1722268260625!5m2!1suk!2sua" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Box>
        </Box>
       
    )
}

export default LocationBlock