import { Box, Button, Slide } from "@mui/material"
import { useNavigate, Link } from "react-router-dom"
import React  from 'react';
import { useState } from "react";
import { Twirl as Hamburger } from 'hamburger-react'

// import { wlaunchWidgetLoader } from "../../wlaunchWidgetLoader";
function MainInfoHeader(){
    const navigate = useNavigate()
    const [isOpen, setOpen] = useState(false)
   
    // function open(){
    //         wlaunchWidgetLoader.init({
    //         url: "https://w.wlaunch.net/i/9ac7609e-d46b-11ed-8e12-617c4a4ae7be",
    //         type: "modal",
    //         keep_state: false,
    //         mobile_new_tab: true,
    //         control: ".wlaunchWidgetLoaderControl",
    //         name: "variant1",
    //         width: "400px"
    //         })
    // }
    const closeMenu = () => {
        setOpen(!isOpen);
        console.log(isOpen)
      };
    return(
        <Box name="container" sx={{
            position: "relative", // Додано
            zIndex: isOpen ? 9999 : 0,
            background: "#FEFAE0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}>
        

        <Slide direction="right" in={isOpen} mountOnEnter unmountOnExit timeout={500}>
        
                <Box
                    sx={{
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: isOpen ? '#333333' : 'transparent',
                        transition: isOpen ? 'background-color 700ms ease-in-out' : 'none',
                        zIndex: 10000, // Збільшено
                    }}
                    >
                        
                    <Box
                    sx={{
                        position: 'fixed',
                        left: isOpen ? 0 : '-100%',
                        top: 0,
                        width: '200px',
                        height: '100%',
                        backgroundColor: isOpen ? '#000000' : 'transparent',
                        transition: isOpen ? 'left 500ms ease-in-out' : 'none',
                        zIndex: 10000, // Збільшено
                    }}
                    >
                       <Hamburger color='white' size={22} toggled={isOpen} toggle={setOpen}></Hamburger> 
                <Box sx={{ width: 'calc(100% - 56px)', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginX: 'auto', paddingY: '56px' }}>
                    <Box name="logo" sx={{maxWidth:{xs:"40%", sm:"50%", md:"60%"}}}>
                        <img width="100%" alt="logo" src="../images/Amigos-logo.png"></img>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '48px', gap: '12px', marginTop: '20px' }}>
                    <Link to='/'><Button onClick={() => closeMenu()} sx={{ color: 'white' }}>Головна</Button></Link>
                    <Link to='/booking'><Button onClick={() => closeMenu()} sx={{ color: 'white' }}>Записатись</Button></Link>
                    <Link to='/prices'><Button onClick={() => closeMenu()} sx={{ color: 'white' }}>Ціни</Button></Link>
                    <Link to={'/dog_grooming'}><Button onClick={() => closeMenu()} sx={{ color: 'white' }}>Грумінг собак</Button></Link>
                    <Link to={'/cat_grooming'}><Button onClick={() => closeMenu()} sx={{ color: 'white' }}>Грумінг котів</Button></Link>

                    </Box>
                </Box>
                </Box>
            </Box>
            </Slide>







            <Box name="logo" sx={{maxWidth:{xs:"25%", sm:"20%", md:"10%"}}}><Link to="/"><img width="100%" alt="logo" src="../images/Amigos-logo.png"></img></Link></Box>

            <Box name="buttons_list" sx={{
                width: "50%",

            }}>
                <Box name="buttons" sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                    <Box sx={{display:{lg:"none"}, position: "relative"}}>
                        <Hamburger color='black' size={22} toggled={isOpen} toggle={setOpen}></Hamburger>
                    </Box>
                    <Button onClick={() => navigate("/booking")} name="booking_button" sx={{
                        display:{xs:'none', lg:"block"},
                        width:{xs:"50%" ,sm:"40%"},
                        fontSize:{xs:"10px", sm:"13px"},
                        height:"3.5em",
                        background:"#E9AE4A",
                        borderRadius:"24px",
                        color:"#000000",
                        fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        textAlign: "center",
                        "&:hover": {
                            border:"2px solid #000000",
                        },
                    }}>Записатись</Button>
                    <Button onClick={() => navigate('/prices')} name="prices_button" sx={{
                        display:{xs:'none', lg:"block"},
                        width:{xs:"50%" ,sm:"40%"},
                        fontSize:{xs:"10px", sm:"13px"},
                        height:"3.5em",
                        border:"2px solid #000000",
                        borderRadius:"24px",
                        color:"#000000",
                        marginLeft: "10px",
                    }}>Ціни</Button>
                    <Button onClick={() => navigate('/cat_grooming')} name="prices_button" sx={{
                        display:{xs:'none', lg:"block"},
                        width:{xs:"50%" ,sm:"40%"},
                        fontSize:{xs:"10px", sm:"13px"},
                        height:"3.5em",
                        border:"2px solid #000000",
                        borderRadius:"24px",
                        color:"#000000",
                        marginLeft: "10px",
                    }}>Грумінг котів</Button>
                    <Button onClick={() => navigate('/dog_grooming')} name="prices_button" sx={{
                        display:{xs:'none', lg:"block"},
                        width:{xs:"50%" ,sm:"40%"},
                        fontSize:{xs:"10px", sm:"13px"},
                        height:"3.5em",
                        border:"2px solid #000000",
                        borderRadius:"24px",
                        color:"#000000",
                        marginLeft: "10px",
                    }}>Грумінг собак</Button>
                </Box>
            </Box>
            {/* {showModal &&(
                <BookingModal handleCloseModal={handleCloseModal} handleSubmit={handleSubmit}/>
            )} */}
            {/* <button class="wlaunchWidgetLoaderControl" onClick={()=> open()}>Show Modal Iframe</button> */}
            
            
            
        </Box>
    )
}

export default MainInfoHeader