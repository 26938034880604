import React from 'react'
import IconsBlock from '../MainPage/IconsBlock'
import Banner from './Banner'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Button, List, ListItem, Grid, Container, ListItemIcon, ListItemText, } from '@mui/material'
import Slider from '../MainPage/PhotosPreview/Slider'
import ServiceCard from './ServiceCard'
import LocationBlock from '../MainPage/LocationBlock/LocationBlock'
import { priceCatsAndBunnys } from '../PricesPage/PriceList';
import BlockForList from '../PricesPage/BlockForList'

export default function GroomingPage() {
    const services = [
        { title: 'Вичісування',  price: 'від 550 грн' },
        { title: 'Стрижка',  price: 'від 600 грн' },
        { title: 'Купання',  price: 'від 250 грн' },
        { title: 'Манікюр',  price: 'від 150 грн' },
        { title: 'Комплексний догляд',  price: 'від 850 грн' },
        { title: 'Грумінг Мейн-Куна',  price: 'від 1100 грн' },
      ];
    const navigate = useNavigate()
            return (
                <div>
                    <Banner/>
                    <Container fixed={true} maxWidth="lg">

                    <Box name="first block" sx={{ border: "4px solid #B66468", display: 'flex', justifyContent: "space-around", flexDirection: { xs: 'column', md: 'row' }, marginTop:'20px', marginBottom:'10px', padding: {xs:'20px', md:'0px'} }}>
               
                        <Box sx={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: { xs: 'column', md: 'row' }, // Змінюємо напрямок для xs і md
                            alignItems: 'center', // Вирівнюємо по центру по вертикалі
                        }}>
                            {/* item1 */}
                            <Box
                            className="item item1"
                            sx={{
                                width: { xs: '100%', md: '150px' }, // Змінюємо ширину для xs і md
                                height: '400px',
                                backgroundColor: '#E9AE4A',
                                display: 'flex', // Додаємо flex для горизонтального розташування елементів
                                alignItems: 'center', // Вирівнюємо по центру по вертикалі
                            }}
                            >
                            {/* Вміст item1 */}
                            </Box>
                            {/* item2 */}
                            <Box
                            className="item item2"
                            sx={{
                                width: '250px',
                                height: '250px',
                                borderRadius: '50%',
                                position: 'absolute',
                                top: '90px',
                                backgroundImage: `url("/images/kit.jpg")`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                            >
                            {/* item 2 */}
                            </Box>
                        </Box>
                        {/* Контейнер для тексту */}
                        <Box sx={{ width: { xs: '100%', md: '60%' } }}>
                            <h3 style={{fontFamily: 'OksFree',
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "35px",
                                    lineHeight: "50px",}}>Догляд за котами</h3>
                            <Typography sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: {xs:"12px", sm:"17px"},
                        lineHeight: "22px",}}>
                            Гігієнічний догляд має велике значення для котів. Своєчасна гігієна тварин передбачає не лише звичайне купання та розчісування, а також уважний догляд за їх вушками, очима, лапками та хвостами.
                            </Typography>
                            <Typography sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: {xs:"12px", sm:"17px"},
                        lineHeight: "22px",}}>
                            Крім того, вичесана шерсть містить менше відмерлої шерсті, що означає, що ваша кішка зможе менше вилизати її. Невичесана як слід шерсть може призводити до утворення ковтунів (і як наслідок проблем зі шкірою) та іноді до проблем з травленням. На жаль не кожен власник має достатньо вільного часу для процедур догляду за котиком. Тому завітайте до нашого грумінг салону Amigos, де наші професіонали з турботою подбають про вашого хвостатого друга.
                            </Typography>
                        </Box>
                </Box>

                <Box name="second block" sx={{display:'flex', flexDirection:'column', alignItems:'center', height:{xs:'700px', sm:'400px'}, justifyContent:'space-around'}}>
                        <Box name="heading for second block" sx={{ display: 'flex', alignItems: 'center', p: 2, justifyContent:"center" }}>
                            {/* Перше зображення */}
                            <img src='/images/scissors.png' alt='scissors_icon' style={{ width: '10%', height: 'auto', transform: 'rotate(180deg)' }} />

                            {/* Текст */}
                            <Typography variant='h3' sx={{
                                fontFamily: 'OksFree',
                                textAlign:"center",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: {xs:"20px", md:"35px"},
                                lineHeight: "50px",
                                margin: "0 20px" // Додали відступи навколо тексту
                            }}>
                                Грумінг для котів: коли, який, як?
                            </Typography>

                            {/* Друге зображення */}
                            <img src='/images/scissors.png' alt='scissors_icon' style={{ width: '10%', height: 'auto' }} />
                        </Box>

                    <Box>
                        <Typography sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: {xs:"12px", sm:"17px"},
                        lineHeight: "25px",}}>
                            Щоб доглядати за шерстю котиків, її можна вичісувати, зістригати або комбінувати ці процедури. В середньому більшості кішкам потрібна стрижка кожні 2-3 місяці. Короткошерсті породи можуть обходитися стрижкою раз на кілька місяців, щоб зберегти чистоту та акуратний вигляд. Довгошерсті породи, навпаки, можуть вимагати більш частих стрижок для запобігання утворенню ковтунів. У період весни і осені найбільше котики линяють і їм потрібна експрес-линька, щоб позбутись зайвої шерсті.
                        </Typography>
                    </Box>

                    <Button onClick={() => navigate("/booking")} name="booking_button" sx={{
                        width:{xs:"50%" ,sm:"40%"},
                        fontSize:{xs:"10px", sm:"13px"},
                        height:"3.5em",
                        background:"#E9AE4A",
                        borderRadius:"24px",
                        color:"#000000",
                        fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        textAlign: "center",
                        "&:hover": {
                            border:"2px solid #000000",
                        },
                    }}>Записатись</Button>
                    


                </Box>

        <Box>
            <h3 style={{fontFamily: 'OksFree',
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "35px",
                                    lineHeight: "27px",
                                    textAlign:'center'}}>Як ми доглядаємо</h3>
            <Grid container spacing={2}>
            
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                    <ServiceCard title={service.title}  price={service.price} />
                    </Grid>
                ))}
            </Grid>

        </Box>


        
    <Box sx={{ display: 'flex', maxWidth:"100%", justifyContent:'space-between', alignItems: 'center', margin: 'auto', height:'700px', flexDirection: { xs: 'column', md: 'row' }, marginTop:{xs:'30px', md:"0px"} }}>
      {/* Картинка котика */}
        <Box sx={{ width: { xs: '100%', md: '50%' }, height: 'auto',  }} >
            <img src="/images/kit2.jpg" alt="Котик" style={{width:'100%', borderRadius: "20px"}} />
        </Box>
 
      

      {/* Список з кастомними буллет марками */}
      <Box sx={{ flexGrow: 1}}>
      <h3 style={{fontFamily: 'OksFree',
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "35px",
                                    lineHeight: "27px",
                                    margin:'20px'}}>Чому вибирають Amigos</h3>
        <List>
          <ListItem>
            <ListItemIcon>
              <img src="/images/bullet.png" alt="Буллет марка" style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <ListItemText sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        }} primary="кожен майстер має свій підхід до хвостатого" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src="/images/bullet.png" alt="Буллет марка" style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <ListItemText sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        }} primary="задоволений клієнт — задоволений майстер" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src="/images/bullet.png" alt="Буллет марка" style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <ListItemText sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        }} primary="любов, турбота, уважність до деталей — наше все" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src="/images/bullet.png" alt="Буллет марка" style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <ListItemText sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        }} primary="працюємо без вихідних 10.00-20.00 год." />
          </ListItem>
        </List>
      </Box>
    </Box>

        
        </Container>

        <Box name="container" sx={{
            width:"100%",
            height:"100%",
            paddingBottom:"20px",
            background:"#B66468"
        }}>
            <Box name="title" sx={{
                width:"100%",
                height:"10em",
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
            }}>
                <Typography sx={{
                    fontFamily: 'OksFree',
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "45px",
                    lineHeight: "50px",
                    color:"#FEFAE0",
                }}>Наші роботи</Typography>
            </Box>


            <Box name="slider" sx={{
                textAlign: "center",
            }}>
                <Slider/>
            </Box>
        </Box>

        <Box className='priceList_elements' sx={{margin: "20px"}}>
                    <Box className='priceList_prices'>
                        <Box className='priceList_prices_element'>
                           
                        {priceCatsAndBunnys.map(({buttonTitle, titleBlockLeft, titleBlockRight, priceList, endText})=> {
                                return <BlockForList buttonTitle={buttonTitle}  titleBlockLeft={titleBlockLeft} titleBlockRight={titleBlockRight} priceList={priceList} endText={endText}>{buttonTitle}</BlockForList>
                            })  
                           
                            }
                        
                           
                    
                       </Box>
                    </Box>
                    
        </Box>
        


    <IconsBlock/>
    <LocationBlock/>




    </div>
  )
}
