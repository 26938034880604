import UpInfoHeader from "./UpInfoHeader"
import MainInfoHeader from "./MainInfoHeader"
import React  from 'react';
function Header(){
    return(
        <div>
           <UpInfoHeader/>
           <MainInfoHeader/>
        </div>
    )
}

export default Header