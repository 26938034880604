import { Box, Typography,  } from "@mui/material"
import React from 'react';
export const pricesPreviewData = [
    {
        title:"Комплексний догляд",
        value:"1",
        startPrice:"від 500 грн",
        descriptionList: "Комплексний догляд — стрижка; або експрес линька; або стрипінг/ тримінг — тип процедури залежно від породи собаки, стану шерсті та побажань клієнта, купання шампунем глибокого очищення та шампунем відповідно до типу шерсті, бальзамом, чищення параанальних залоз (за потреби +100 грн), чищення вушок, гігієнічна чистка зубів (+100 грн), вичісування, сушка з укладкою, обрізання кігтів, парфум",
        
    },
    {
        title:"Гігієнічний догляд",
        value:"2",
        startPrice:"від 400 грн",
        descriptionList: "Гігієнічний догляд - купання шампунем глибокого очищення та шампунем відповідно до типу шерсті, бальзамом, чищення параанальних залоз (за потреби +100 грн), чищення вушок, гігієнічна чистка зубів (+100 грн), вичісування, сушка з укладкою, обрізання кігтів, гігєна подушечок лап та обкантовка лап, гігієна інтимної зони, стрижка мордочки (+100 грн), стрижка біля очок або зав'язування бантика, парфум"
    },
    {
        title:"Додаткові послуги",
        value:"3",
        startPrice:"від 100 грн",
        descriptionList: "Гігієнічна чистка зубів, обрізання кігтиків, експрес линька, тримінг та ін."
    },
    {
        title:"Догляд за котами і кроликами",
        value:"4",
        startPrice:"від 400 грн",
        descriptionList: "Вичісування, обрізання кігтиків, купання та ін."
    },


    
]
function PricesPreview(){
    return(
        <Box>
            <Box name="our services" sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent:"space-around",
                alignItems: "center",
                flexWrap:"wrap",
            }}>
                {pricesPreviewData.map(({title, startPrice, descriptionList}) => (
                    <Box sx={{
                        width:"35%",
                        height:"400px",
                        padding:"30px",
                        boxShadow:" 24px 24px 0px 0px rgba(182,100,104,1)",
                        background: "#fff",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        marginTop: "50px",
                    }}> 
                            <Typography sx={{
                                fontFamily: 'OksFree',
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "26px",
                                lineHeight: "27px",
                            }}>{title}</Typography>
                            <Typography sx={{
                                fontFamily:"Rubik",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "32px",
                                lineHeight: "27px",
                                marginTop:"50px",
                            }}>{startPrice}</Typography>
                            <Box sx={{
                                marginTop:"20px"
                            }}>
                                {/* {descriptionList.map((element) =>( */}
                                    <Typography sx={{
                                        fontFamily:"Rubik",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: {md:"12px", lg:"16px"},
                                        lineHeight: "27px",
                                    }}>{descriptionList}</Typography>
                                {/* ))} */}
                            </Box>

                    </Box>
                ))

                }
                
            </Box>

                
        </Box>
    )
}

export default PricesPreview