import React from 'react'
import { Paper, Typography, SvgIcon } from '@mui/material';
import PriceTagIcon from '@mui/icons-material/LocalOffer';
export default function ServiceCard({ title, description, price }) {
  return (
    <Paper sx={{ p: 4, position: 'relative', borderRadius:'20px', textAlign:'center', background:'#B66468', color:'#ffff'}}>
      <Typography variant="h5" sx={{fontFamily: 'OksFree',
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: {xs:"30px", md:"40px"},
                                    lineHeight: "27px",}}>{title}</Typography>
      <Typography variant="body1">{description}</Typography>
      <Typography variant="body1" sx={{ position: 'absolute', bottom: 0, right: 10 }}>
      <SvgIcon component={PriceTagIcon} sx={{ fontSize: '1em', marginLeft: '4px' }} />
        {price}
      </Typography>
    </Paper>
  )
}
