import { Box, Button } from "@mui/material"
import { useState, useEffect } from "react"
import "../.././styles/tabsPricesStyles.css"
import BlockForList from "./BlockForList"
import React  from 'react';

export const priceMain = [{
    buttonTitle:"КОМПЛЕКСНИЙ ДОГЛЯД",
    titleBlockLeft:"ПОРОДА",
    titleBlockRight:"Вартість(грн)",
    priceList: [
        {
            listName: "Малі",
            list:[
                {service:"Той тер‘єр", price:"600"},
                {service:"Пекінес", price:"700"},
                {service:"Папільон", price:"600"},
                {service:"Чихуахуа", price:"600 з вичісуванням/ 650 зі стрижкою"},
                {service:"Китайська хохлата пухова/ гола", price:"700"},
                {service:"Біверйоркширський тер‘єр", price:"750"},
                {service:"Мопс", price:"700"},
                {service:"Японський хін", price:"750"},
                {service:"Мальтійська болонка", price:"750"},
                {service:"Йоркширський тер’єр", price:"750"},
                {service:"Інші породи собак до 8 кг", price:"від 750"},
                {service:"Ши-тцу", price:"750"},
                {service:"Такса довгошерстна/ жорсткошенрстна", price:"зі стрижкою 750, з вичісуванням 700"},
                {service:"Шпіц померанський", price:"800"},
                {service:"Шпіц німецький", price:"850"},
                {service:"Кавалер кінг чарльз спаніель", price:"850"},
                {service:"Той, карликовий або малий пудель", price:"900"},
                {service:"Йоркіпу", price:"900"},
                {service:"Кавапу", price:"900"},
                {service:"Кокапу", price:"900"},
                {service:"Мальтіпу", price:"900"},
                {service:"Бішон", price:"900"},
                {service:"Цвергшнауцер", price:"зі стрижкою 900"},                
            ]
        },
        {
            listName:"Середні",
            list:[
                {service:"Французький бульдог", price:"750"},
                {service:"Джек рассел тер’єр", price:"650"},
                {service:"Мопс", price:"700"},
                {service:"Американський бульдог", price:"800"},
                {service:"Такса довгошерстна/ жорсткошенрстна", price:"зі стрижкою 750, з вичісуванням 700"},
                {service:"Бігль", price:"900"},
                {service:"Континентальни той-спаніель", price:"650"},
                {service:"Кокер спаніель", price:"900"},
                {service:"Цвергшнауцер", price:"900"},
                {service:"Фокс тер’єр", price:"зі стрижкою 850, тримінг 1050"},
                {service:"Скотч тер‘єр", price:"зі стрижкою 850, тримінг 1050"},
                {service:"Вельш коргі", price:"950"},
                {service:"Грифон", price:"зі стрижкою  800, тримінг  950"},
                {service:"Вест хайленд Вайт тер’єр", price:"зі стрижкою  850, тримінг  1150"},
                {service:"Лаготто", price:"1100"},
                {service:"Вельш тер‘єр", price:"зі стрижкою  900, тримінг  1200"},
                {service:"Лабрадудль", price:"1300"},
                {service:"Різеншнауцер", price:"зі стрижкою  1400, тримінг  1700"},
                {service:"Самоїд", price:"від 2000"},
                {service:"Інші породи собак 8-15 кг", price:"від 750-950"},
            ]
        },
        {
            listName:"Великі",
            list:[
                {service:"Кане Корсо", price:"1100"},
                {service:"Доберман", price:" 1100"},
                {service:"Мітельшнауцер", price:"зі стрижкою  1100, тримінг  1400"},
                {service:"Ретривер", price:"1400"},
                {service:"Австралійська вівчарка", price:"1400"},
                {service:"Дог", price:"1300"},
                {service:"Ардель тер‘єр", price:"зі стрижкою  1400, тримінг  1600"},
                {service:"Лайка", price:"1500"},
                {service:"Хаскі", price:"1500"},
                {service:"Бордер колі", price:"1300"},
                {service:"Різеншнауцер", price:"зі стрижкою  1400, тримінг  1700"},
                {service:"Американська акіта", price:"1600"},
                {service:"Німецька вівчарка", price:"1600"},
                {service:"Інші породи собак 15 кг ", price:"від 950"},
            ]
        },    
],
    endText:"Комплексний догляд — стрижка; або експрес линька; або тримінг — тип процедури залежно  породи собаки, стану шерсті та побажань клієнта, купання шампунем глибокого очищення та шампунем поно до типу шерсті, бальзамом, чищення параанальних залоз (за потреби +100 грн), чищення вушок, гігієнічна чистка зубів (+100 грн), вичісування, сушка з укладкою, обрізання кігтів, парфум"
}]
const priceAddition = [{
    buttonTitle:"ДОДАТКОВІ ПОСЛУГИ",
    titleBlockLeft:"Додаткова послуга",
    titleBlockRight:"Вартість(грн)",
    priceList: [
        {
        listName:"",
           list:[
            {service:"Вичісування/ експрес-линька", price:"200/ год"},
            {service:"Манікюр (Обрізання кігтів, запилення фрезером, зволоження подушечок бальзамом) ", price:"150"},
            {service:"“Відкрити очі“", price:"100"},
            {service:"Гігієнічна чистка зубів", price:"100"},
            {service:"Маска (додатково до вартості основної процедури)", price:"100"},
            {service:"Стриппінг/ тримінг", price:"400/ год"},
            ]
        },
        
    ],
    endText:"Додаткові послуги - гігієнічна чистка зубів, обрізання кігтиків, експрес линька, тримінг та ін."
}]


const priceGigiena = [{
    buttonTitle:"ГІГІЄНІЧНИЙ ДОГЛЯД",
    titleBlockLeft:"ПОРОДА",
    titleBlockRight:"Вартість(грн)",
    priceList: [
        {
            listName: "Малі",
            list:[
                {service:"Той тер‘єр", price:"600"},
                {service:"Йоркширський тер’єр", price:"650"},
                {service:"Японський хін", price:"650"},
                {service:"Китайська хохлата пухова/ гола", price:"600"},
                {service:"Пекінес", price:"600"},
                {service:"Мальтійська болонка", price:"650"},
                {service:"Чихуахуа", price:"500"},
                {service:"Ши-тцу", price:"700"},
                {service:"Папільон", price:"500"},
                {service:"Мопс", price:"600"},
                {service:"Біверйоркширський тер‘єр", price:"650"},
                {service:"Шпіц померанський", price:"700"},
                {service:"Такса довгошерстна/ жорсткошенрстна", price:"600"},
                {service:"Шпіц німецький", price:"750"},
                {service:"Той, карликовий або малий пудель", price:"800"},
                {service:"Мальтіпу", price:"800"},
                {service:"Йоркіпу", price:"800"},
                {service:"Кавалер кінг чарльз спаніель", price:"750"},
                {service:"Кавапу", price:"800"},
                {service:"Кокапу", price:"800"},
                {service:"Бішон", price:"800"},
                {service:"Інші породи собак до 8 кг", price:"від 650"},
                
            ]
        },
        {
            listName:"Середні",
            list:[
                {service:"Французький бульдог", price:"600"},
                {service:"Американський бульдог", price:"900"},
                {service:"Джек рассел тер’єр", price:"600"},
                {service:"Мопс", price:"600"},
                {service:"Такса довгошерстна/ жорсткошенрстна", price:"600"},
                {service:"Бігль", price:"800"},
                {service:"Континентальни той-спаніель", price:"750"},
                {service:"Кавалер кінг чарльз спаніель", price:"750"},
                {service:"Кокер спаніель", price: "800"},
                {service:"Цвергшнауцер", price:"800"},
                {service:"Фокс тер’єр", price:"750"},
                {service:"Скотч тер‘єр", price:"750"},
                {service:"Вельш коргі", price:"800"},
                {service:"Грифон", price:"700"},
                {service:"Вест хайленд Вайт тер’єр", price:"750"},
                {service:"Вельш тер‘єр", price:"800"},
                {service:"Логотто", price:"1000"},
                {service:"Різеншнауцер", price:"1300"},
                {service:"Самоїд", price:"1900"},
                {service:"Інші породи собак 8-15 кг", price:"від 650-850"},

            ]
        },
        {
            listName:"Великі",
            list:[
                {service:"Лабрадор", price:"1000"},
                {service:"Мітельшнауцер", price:"1000"},
                {service:"Кане Корсо", price:"1000"},
                {service:"Доберман", price:"1000"},
                {service:"Ретривер", price:"1300"},
                {service:"Австралійська вівчарка", price:"1300"},
                {service:"Дог", price:"1200"},
                {service:"Бордер — колі", price:"1200"},
                {service:"Ардель тер‘єр", price:"1300"},
                {service:"Лайка", price:"1400"},
                {service:"Хаскі", price:"1400"},
                {service:"Американська акіта", price:"1500"},
                {service:"Німецька вівчарка", price:"1500"},
                {service:"Самоїд", price:"1900"},           
                {service:"Інші породи собак  15 кг", price:"від 850"},
            ]
        },      
    ],
    endText:"Гігієнічний догляд - купання шампунем глибокого очищення та шампунем поно до типу шерсті, бальзамом, чищення параанальних залоз (за потреби +100 грн), чищення вушок, гігієнічна чистка зубів (+100 грн), вичісування, сушка з укладкою, обрізання кігтів, гігєна подушечок лап та обкантовка лап, гігієна інтимної зони, стрижка мордочки (+100 грн), стрижка біля очок або зав'язування бантика, парфум."
}]


export const priceCatsAndBunnys = [{
    buttonTitle:"Догляд за котами та кроликами",
    titleBlockLeft:"Догляд котів та кроликів",
    titleBlockRight:"Вартість(грн)",
    priceList: [
        {
            listName:"",
            list:[
                {service:"Вичісування котика", price:"600"},
                {service:"Вичісування Мейн-кун", price:"800"},
                {service:"Стрижка котика (без купання)", price:"650"},
                {service:"Стрижка Мейн-кун", price:"900"},
                {service:"Вичісування/стрижка котика + купання", price:"950"},
                {service:"Мейн-кун комплекс (купання, вичісування, обрізання кігтиків)", price:"1200"},
                {service:"Мейн-кун комплекс зі стрижкою", price:"1200"},
                {service:"Вичісування кролика", price:"400-450"},
                {service:"Обрізання кігтиків кролик/ котик", price:"100"},
                {service:"Котик комплекс (купання, вичісування, обрізання кігтиків)", price:"1100"},
                {service:"Чистка вушок котика", price:"100"},
                {service:"Купання/ сушка котика", price:"600"},
            ]
        },
        
    ],
    endText:"Догляд за котами і кроликами - вичісування, обрізання кігтиків, купання та ін."
}]

const types = ["КОМПЛЕКСНИЙ ДОГЛЯД", "ДОДАТКОВІ ПОСЛУГИ", "ГІГІЄНІЧНИЙ ДОГЛЯД", "ДОГЛЯД ЗА КОТАМИ ТА КРОЛИКАМИ"]
function PriceList(){
    const [active, setActive] = useState(types[0])
    useEffect(() => {
        // Обновляем заголовок документа с помощью API браузера
        setActive(types[0])
    },[]);
    useEffect(() => {
        setActive(types[0]) 
      }, []);
    return(

        <Box className='main_container' sx={{
            width:{xs:"95%", md:"80%"},
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
        }}>
            
            <Box className='buttons_container' sx={{
                display:"flex",
                width:{xs:"90%", sm:"85%", md:"75%"},
                justifyContent:"space-around",
            }}>
                 
                {types.map((type)=>(
                    <Button key={type} active={active === type} onClick={(e)=> setActive(type)} className={`list-item ${active === type && "active"}`} sx={{
                        width:{xs:"25%", sm:"20%"},
                        height:{xs:"6em", sm:"4em"},
                        background:"#E9AE4A",
                        color:"#000000",
                        borderRadius:"20px 20px 0 0",
                        "&:active": {
                            border:"2px solid #000000",
                            background:"#FEFAE0",
                            borderBottom:"0"
                        },
                        fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: { xs:"8px", sm:"10px", md:"13px"},
                        lineHeight: "17px",
                        textAlign: "center",
                    }}>{type}</Button>
                   
                ))

                }
                
                
            </Box>
           
            

            <Box className='priceList' sx={{
                width:"100%"
            }}>
                <Box className='priceList_elements'>
                    <Box className='priceList_prices'>
                        <Box className='priceList_prices_element'>
                            {active === "КОМПЛЕКСНИЙ ДОГЛЯД" ? priceMain.map(({buttonTitle, titleBlockLeft, titleBlockRight, priceList, endText})=> {
                                return <BlockForList buttonTitle={buttonTitle}  titleBlockLeft={titleBlockLeft} titleBlockRight={titleBlockRight} priceList={priceList} endText={endText}>{buttonTitle}</BlockForList>
                            }) 
                            : active === "ДОДАТКОВІ ПОСЛУГИ" ? priceAddition.map(({buttonTitle, titleBlockLeft, titleBlockRight, priceList, endText})=> {
                                return <BlockForList buttonTitle={buttonTitle}  titleBlockLeft={titleBlockLeft} titleBlockRight={titleBlockRight} priceList={priceList} endText={endText}>{buttonTitle}</BlockForList>
                            }) 
                            : active === "ГІГІЄНІЧНИЙ ДОГЛЯД" ? priceGigiena.map(({buttonTitle, titleBlockLeft, titleBlockRight, priceList, endText})=> {
                                return <BlockForList buttonTitle={buttonTitle}  titleBlockLeft={titleBlockLeft} titleBlockRight={titleBlockRight} priceList={priceList} endText={endText}>{buttonTitle}</BlockForList>
                            })
                            : priceCatsAndBunnys.map(({buttonTitle, titleBlockLeft, titleBlockRight, priceList, endText})=> {
                                return <BlockForList buttonTitle={buttonTitle}  titleBlockLeft={titleBlockLeft} titleBlockRight={titleBlockRight} priceList={priceList} endText={endText}>{buttonTitle}</BlockForList>
                            })  
                           
                            }
                       </Box>
                    </Box>
                    
                </Box>
            </Box>
           
        </Box>
        
        
    )
}

export default PriceList