import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
    const navigate = useNavigate()
  return (
    <Box
      sx={{
        backgroundImage: 'url("/images/dog_grooming.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: {xs:"500px",md:'800px'}, // Задаємо висоту банера
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white', // Колір тексту
        flexDirection:'column',
        fontFamily: 'OksFree',
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: {xs:"20px", md:"40px"},
        lineHeight: "27px",
        backgroundPositionX: "right",
      }}
    >
      <h1>Грумінг Собак</h1>
      <Box sx={{width:'80%', display:'flex', flexDirection:'column'}}>
        <Typography sx={{fontFamily: 'Rubik',
                        fontSize:{xs:"20px", sm:"25px"},
                        fontStyle: "normal",
                        color:'black',
                        fontWeight: "800",
                        lineHeight: "17px",
                        marginBottom:'20px'}}>Amigos - з турботою про собак</Typography>
        <Button onClick={() => navigate("/booking")} name="booking_button" sx={{
                        width:{xs:"30%" ,sm:"20%"},
                        fontSize:{xs:"10px", sm:"13px"},
                        height:"3.5em",
                        background:"#E9AE4A",
                        borderRadius:"24px",
                        color:"#000000",
                        fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        textAlign: "center",
                        "&:hover": {
                            border:"2px solid #000000",
                        },
                    }}>Записатись</Button>
      </Box>

    </Box>
  );
};

export default Banner;





