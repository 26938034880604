import React from 'react'
import IconsBlock from '../MainPage/IconsBlock'
import Banner from './Banner'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Button, List, ListItem, Grid, Container, ListItemIcon, ListItemText, } from '@mui/material'
import Slider from '../MainPage/PhotosPreview/Slider'
import ServiceCard from './ServiceCard'
import LocationBlock from '../MainPage/LocationBlock/LocationBlock'
import { priceMain } from '../PricesPage/PriceList'
import BlockForList from '../PricesPage/BlockForList'

export default function DogGroomingPage() {
    const services = [
        { title: 'Комплексний догляд',  price: 'від 450 грн' },
        { title: 'Гігієнічний догляд',  price: 'від 600 грн' },
        { title: 'Вичісування',  price: 'від 350 грн' },
        { title: 'Чистка зубів',  price: 'від 100 грн' },
        { title: 'Стриппінг/ тримінг',  price: 'від 400 грн' },
        { title: 'Маска',  price: 'від 100 грн' },
      ];
    const navigate = useNavigate()
            return (
                <div>
                    <Banner/>
                    <Container fixed={true} maxWidth="lg">

                    <Box name="first block" sx={{ border: "4px solid #B66468", display: 'flex', justifyContent: "space-around", flexDirection: { xs: 'column', md: 'row' }, marginTop:'20px', marginBottom:'10px', padding: {xs:'20px', md:'0px'} }}>
               
                        <Box sx={{
                            position: 'relative',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: { xs: 'column', md: 'row' }, // Змінюємо напрямок для xs і md
                            alignItems: 'center', // Вирівнюємо по центру по вертикалі
                        }}>
                            {/* item1 */}
                            <Box
                            className="item item1"
                            sx={{
                                width: { xs: '100%', md: '150px' }, // Змінюємо ширину для xs і md
                                height: '400px',
                                backgroundColor: '#E9AE4A',
                                display: 'flex', // Додаємо flex для горизонтального розташування елементів
                                alignItems: 'center', // Вирівнюємо по центру по вертикалі
                            }}
                            >
                            {/* Вміст item1 */}
                            </Box>
                            {/* item2 */}
                            <Box
                            className="item item2"
                            sx={{
                                width: '250px',
                                height: '250px',
                                borderRadius: '50%',
                                position: 'absolute',
                                top: '90px',
                                backgroundImage: `url("/images/imgCarousel/11.jpg")`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                            >
                            {/* item 2 */}
                            </Box>
                        </Box>
                        {/* Контейнер для тексту */}
                        <Box sx={{ width: { xs: '100%', md: '60%' } }}>
                            <h3 style={{fontFamily: 'OksFree',
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "35px",
                                    lineHeight: "50px",}}>Догляд за собаками</h3>
                            <Typography sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: {xs:"12px", sm:"17px"},
                        lineHeight: "22px",}}>
                            Розчісування видаляє відмерлі волоски, запобігає утворенню ковтунів і масажує шкіру собаки, покращуючи її кровопостачання. Частота розчісування залежить від типу шерсті вашого вихованця. Купння. Ця процедура допомагає позбавити вихованця бруду, запаху, бактерій на шерсті та шкірі. 
                            </Typography>
                            <Typography sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: {xs:"12px", sm:"17px"},
                        lineHeight: "22px",}}>
                            Частота митя залежить від стану шерстяного покриву тварини, умов утримання та наявності шкірних захворювань. Стрижка необхідна для підтримання чистоти і форми шерсті, профілактики утворення ковтунів, а влітку – для поліпшення терморегуляції.
                            </Typography>
                        </Box>
                </Box>

                <Box name="second block" sx={{display:'flex', flexDirection:'column', alignItems:'center', height:{xs:'700px', sm:'400px'}, justifyContent:'space-around'}}>
                        <Box name="heading for second block" sx={{ display: 'flex', alignItems: 'center', p: 2, justifyContent:"center" }}>
                            {/* Перше зображення */}
                            <img src='/images/scissors.png' alt='scissors_icon' style={{ width: '10%', height: 'auto', transform: 'rotate(180deg)' }} />

                            {/* Текст */}
                            <Typography variant='h3' sx={{
                                fontFamily: 'OksFree',
                                textAlign:"center",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: {xs:"20px", md:"35px"},
                                lineHeight: "50px",
                                margin: "0 20px" // Додали відступи навколо тексту
                            }}>
                                Грумінг для собак: коли, який, як?
                            </Typography>

                            {/* Друге зображення */}
                            <img src='/images/scissors.png' alt='scissors_icon' style={{ width: '10%', height: 'auto' }} />
                        </Box>

                    <Box>
                        <Typography sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: {xs:"12px", sm:"17px"},
                        lineHeight: "25px",}}>
                            Грумінг потрібен всім собакам. До грумінгу собаку потрібно привчати з раннього віку. Поради по догляду за шерстю в різні пори року: 
                                Зима: Менше купань і більше уваги до розчісування.
                                Літо: Полегшена стрижка, регулярна перевірка вух та лап на предмет паразитів та сміття.
                                Осінь/Весна: Посилене розчісування для керування линькою, чищення лап після прогулянок.
                        </Typography>
                    </Box>

                    <Button onClick={() => navigate("/booking")} name="booking_button" sx={{
                        width:{xs:"50%" ,sm:"40%"},
                        fontSize:{xs:"10px", sm:"13px"},
                        height:"3.5em",
                        background:"#E9AE4A",
                        borderRadius:"24px",
                        color:"#000000",
                        fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        textAlign: "center",
                        "&:hover": {
                            border:"2px solid #000000",
                        },
                    }}>Записатись</Button>
                    


                </Box>

        <Box>
            <h3 style={{fontFamily: 'OksFree',
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "35px",
                                    lineHeight: "27px",
                                    textAlign:'center'}}>Як ми доглядаємо</h3>
            <Grid container spacing={2}>
            
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                    <ServiceCard title={service.title}  price={service.price} />
                    </Grid>
                ))}
            </Grid>

        </Box>


        
    <Box sx={{ display: 'flex', maxWidth:"100%", justifyContent:'space-between', alignItems: 'center', margin: 'auto', height:'700px', flexDirection: { xs: 'column', md: 'row' }, marginTop:{xs:'30px', md:"0px"} }}>
      {/* Картинка котика */}
        <Box sx={{ width: { xs: '100%', md: '50%' }, height: 'auto',  }} >
            <img src="/images/imgCarousel/tenth.jpg" alt="Котик" style={{width:'100%', borderRadius: "20px"}} />
        </Box>
 
      

      {/* Список з кастомними буллет марками */}
      <Box sx={{ flexGrow: 1}}>
      <h3 style={{fontFamily: 'OksFree',
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "35px",
                                    lineHeight: "27px",
                                    margin:'20px'}}>Чому вибирають Amigos</h3>
        <List>
          <ListItem>
            <ListItemIcon>
              <img src="/images/bullet.png" alt="Буллет марка" style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <ListItemText sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        }} primary="кожен майстер має свій підхід до хвостатого" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src="/images/bullet.png" alt="Буллет марка" style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <ListItemText sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        }} primary="задоволений клієнт — задоволений майстер" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src="/images/bullet.png" alt="Буллет марка" style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <ListItemText sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        }} primary="любов, турбота, уважність до деталей — наше все" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <img src="/images/bullet.png" alt="Буллет марка" style={{ width: '20px', height: '20px' }} />
            </ListItemIcon>
            <ListItemText sx={{fontFamily: 'Rubik',
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "14px",
                        }} primary="працюємо без вихідних 10.00-20.00 год." />
          </ListItem>
        </List>
      </Box>
    </Box>

        
        </Container>

        <Box name="container" sx={{
            width:"100%",
            height:"100%",
            paddingBottom:"20px",
            background:"#B66468"
        }}>
            <Box name="title" sx={{
                width:"100%",
                height:"10em",
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
            }}>
                <Typography sx={{
                    fontFamily: 'OksFree',
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "45px",
                    lineHeight: "50px",
                    color:"#FEFAE0",
                }}>Наші роботи</Typography>
            </Box>


            <Box name="slider" sx={{
                textAlign: "center",
            }}>
                <Slider/>
            </Box>
        </Box>

        <Box className='priceList_elements' sx={{margin: "20px"}}>
                    <Box className='priceList_prices'>
                        <Box className='priceList_prices_element'>
                           
                        {priceMain.map(({buttonTitle, titleBlockLeft, titleBlockRight, priceList, endText})=> {
                                return <BlockForList buttonTitle={buttonTitle}  titleBlockLeft={titleBlockLeft} titleBlockRight={titleBlockRight} priceList={priceList} endText={endText}>{buttonTitle}</BlockForList>
                            })  
                           
                            }
                        
                           
                    
                       </Box>
                    </Box>
                    
        </Box>
        


    <IconsBlock/>
    <LocationBlock/>




    </div>
  )
}
